import { ModelDefinition, SingletonModelDefinition, RecordId} from './RestfulModelStore';


export type EntityFields = {
  id: number,
  name: string,
  company_id: string,
  analysis_date: string,
  job_processing?: string,
  job_progress?: [number,number],
  retails: boolean
}
export const Entity: ModelDefinition<EntityFields> = {
  name: "entity",
  inflections: {
    plural: 'entities',
    title: "Entity"
  },
  singleton: false
};

export type InventoryTargetFields = {
  id: number | string,
  entity_id: number,
  vendor_id: number,
  days_supply: string, //decimal
  strain_count: number,
  product_type_id: string,
  flower_type_ids: number[],
  package_size_ids: string[],
  type: "flower_type_target"|"sell_through_target",
  target_groups: {
    package_size_id: string,
    on_target: boolean,
    failed_target: boolean,
    target_tuples: {
      product_id: number,
      product_sales_velocity_id: string,
      on_target: boolean
    }[]
  }[],
  failed_target: boolean,
  on_target: boolean,
  autogenerated: boolean,
  tagged?: string,
  tags: string[]
};
export const InventoryTarget: ModelDefinition<InventoryTargetFields> = {
  name: 'inventory_target',
  inflections: {
    plural: 'inventory_targets',
    title: 'InventoryTarget'
  },
  singleton: false
};

export type VendorSummaryFields = {
  id: string,
  entity_id: number, 
  date: string, 
  trailing_days: number, 
  source_vendor_id: number, 
  product_type: string, 
  contribution_margin: string, 
  gross_sales: string, 
  transactions: number,
  units: number,
  // All of these are relative to the median vendor
  relative_contribution_margin: number, 
  relative_gross_sales: number, 
  relative_transactions: number,
  relative_units: number

}
export const VendorSummary: ModelDefinition<VendorSummaryFields> = {
  name: 'vendor_summary',
  inflections: {
    plural: 'vendor_summaries',
    title: 'VendorSummary'
  },
  singleton: false
};

export type DetailedVendorSummaryFields = {
  id: string,
  entity_id: number, 
  date: string, 
  trailing_days: number, 
  source_vendor_id: number, 
  product_type: string, 
  weight_bucket: string,
  contribution_margin: string, 
  gross_sales: string, 
  transactions: number,
  units: number,
  units_cost: string
}
export const DetailedVendorSummary: ModelDefinition<DetailedVendorSummaryFields> = {
  name: 'detailed_vendor_summary',
  inflections: {
    plural: 'detailed_vendor_summaries',
    title: 'DetailedVendorSummary'
  },
  singleton: false
};

export type PackageSizeFields = {
  id: string,
  display_text: string
  gram_weight: number
};
export const PackageSize: ModelDefinition<PackageSizeFields> = {
  name: "package_size",
  inflections: {
    plural: 'package_sizes',
    title: 'PackageSize'
  },
  singleton: false
}

export type ProductTypeFields = {
  id: string, 
  name: string,
  valued_by_weight: boolean,
  is_marijuana: boolean
};

export const ProductType: ModelDefinition<ProductTypeFields> = {
  name: 'product_type',
  inflections: {
    plural: 'product_types',
    title: 'ProductType'
  },
  singleton: false
};

export type FlowerTypeFields = {
  id: number,
  name: string
};
export const FlowerType: ModelDefinition<FlowerTypeFields> = {
  name: "flower_type",
  inflections: {
    plural: 'flower_types',
    title: "FlowerType"
  },
  singleton: false
}

export type SessionFields = {
  id: number, //TODO: Probably doesn't exist
  current_user_id: number,
  //Hackey used only by the create
  greenbits_username?: string,
  greenbits_password?: string,
  token?: string
}
export const Session: SingletonModelDefinition<SessionFields> = {
  name: 'session',
  singleton: true,
  inflections: {
    plural: 'sessions',
    title: 'Session'
  }
};

export type CompanyFields = {
  id: RecordId<string>,
  name: string, 
  entity_id: number
}
export const Company: ModelDefinition<CompanyFields> = {
  name: 'company',
  inflections: {
    plural: 'companies',
    title: 'Company'
  },
  singleton: false
};

export type UserFields = {
  id: number,
  name: string,
  email: string,
  accessible_company_ids: string[],
  accessible_entity_ids: number[],
  role: "retailer"|"vendor_manager"
};
export const User: ModelDefinition<UserFields> = {
  name: "user",
  inflections: {
    plural: 'users',
    title: 'User'
  },
  singleton: false
};

export type ProductFields = {
  id: number,
  name: string,
  category: string,
  product_type_id: string,
  quantity: number,
  dollar_sell_price: string,
  dollar_purchase_price: string,
  flower_type_id?: number,
  package_size_id: string,
  markup: number,
  greenbits_product_id: string
};

export const Product: ModelDefinition<ProductFields> = {
  name: "product",
  inflections: {
    plural: 'products',
    title: 'Product'
  },
  singleton: false
};

export type CorporateSummaryFields = {
  id: string,
  date: string,
  gross_sales: number,
  sold_cogs: number,
  pricing: {
    price_per_gram: number[],
    skus_density: number[],
    units_density: number[],
    margin_density: number[],
    bucket_averages: {
      [package_size: string]: number
    }
  }
};
export const CorporateSummary: ModelDefinition<CorporateSummaryFields> = {
  name: "corporate_summary",
  inflections: {
    plural: 'corporate_summaries',
    title: 'CorporateSummary'
  },
  singleton: false
};

export type ScheduledSalesFields = {
  id: number,
  start: string,
  end?: string,
  sale_price: string,
  end_price?: string,
  allow_discounts: boolean,
  product_id: ProductFields['id']
};
export const ScheduledSale: ModelDefinition<ScheduledSalesFields> = {
  name: "scheduled_sale",
  inflections: {
    plural: 'scheduled_sales',
    title: 'ScheduledSale'
  },
  singleton: false
};

export const Subscription: ModelDefinition<{
  id: number
}> = {
  name: 'subscription',
  inflections: {
    plural: 'subscriptions',
    title: 'Subscription'
  },
  singleton: false
};

export const ProductSalesVelocity: ModelDefinition<{
  id: string | number,
  entity_id: string | number,
  date: string,
  product_id: string | number,
  trailing_days: number,
  exhaust_date: string,
  days_in_stock: number,
  sold_units: number,
  velocity: number
}> = {
  name: "product_sales_velocity",
  inflections: {
    plural: 'product_sales_velocities',
    title: 'ProductSalesVelocity'
  },
  singleton: false
};

export type PurchaseOrderFields = {
  id: number,
  retailer_id: number,
  vendor_id: number,
  excluded_product_ids: ProductFields['id'][],
  lead_time: number,
  issue_date: string,
  delivery_date: string,
  line_items: {
    product_id: number,
    qty: number,
    source_inventory_target_ids: number[],
    target_days: number
  }[],
  missing_needs: {
    missing_count: number,
    package_size_id: RecordId,
    flower_type_ids: RecordId[],
    product_type_id: RecordId,
    product_ids: ProductFields['id'][]
  }[],
  unconsidered_products: {
    product_id: ProductFields["id"],
    meeting_target_count: number,
    low_velocity_count: number,
    no_sales_data: boolean,
    excluded: boolean,
    needs_order: boolean
  }[]
};

export const PurchaseOrder: ModelDefinition<PurchaseOrderFields> = {
  name: "purchase_order",
  inflections: {
    plural: 'purchase_orders',
    title: "PurchaseOrder"
  },
  singleton: false,
  server_side_new: true
};

export type ProductTagFields = {
  id: string,
  tag: string
};

export const ProductTag: ModelDefinition<ProductTagFields> = {
  name: "product_tag",
  inflections: {
    plural: 'product_tags',
    title: "ProductTag"
  },
  singleton: false,
};

export type VendorPreferenceFields = {
  id: number,
  retailer_id: number,
  vendor_id: number,
  autogenerate_targets: boolean
}

export const VendorPreference: ModelDefinition<VendorPreferenceFields> = {
  name: "vendor_preference",
  inflections: {
    plural: 'vendor_preferences',
    title: 'VendorPreference'
  },
  singleton: false,
  server_side_new: true
}

export type InventoryItemFields = {
  id: number,
  entity_id: number,
  product_id: number,
  sku: string,
  acquisition_date: string,
  first_sale: string,
  last_sale: string,
  sold_count: number,
  return_count: number,
  adjustment_count: number,
  quantity: number,
  velocity: number,
  purchase_price: string,
  lock_version: number
}

export const InventoryItem: ModelDefinition<InventoryItemFields> = {
  name: "inventory_item",
  inflections: {
    plural: "inventory_items",
    title: "InventoryItem"
  },
  singleton: false,
  server_side_new: false
};

export type InventorySummaryFields = {
  id: number,
  entity_id: EntityFields['id'],
  summaries: {
    status: "pending_sale" | "not_for_sale" | "suspect_pending" | "suspect" | "selling" | "pending_destruction" | "pending_fifo" | "fifo_error",
    unpriced: boolean,
    is_marijuana: boolean,
    units: number,
    value: number,
    lot_count: number
  }[]
};

export const InventorySummary: ModelDefinition<InventorySummaryFields> = {
  name: "inventory_summary",
  inflections: {
    plural: "inventory_summaries",
    title: "InventorySummary"
  },
  singleton: false,
  server_side_new: false
}

export type AdjustmentSummaryFields = {
  id: string, 
  entity_id: EntityFields['id'],
  week: string,
  product_type_id: ProductTypeFields['id'],
  unit_adjustment: number,
  value_adjustment: number
};

export const AdjustmentSummary: ModelDefinition<AdjustmentSummaryFields> = {
  name: "adjustment_summary",
  inflections: {
    plural: "adjustment_summaries",
    title: "AdjustmentSummary"
  },
  singleton: false,
  server_side_new: false
};

export type VendorManagerFields = {
  id: number, 
  retailer_id: EntityFields['id'],
  vendor_id:   EntityFields['id'],
  user_id:     UserFields['id'],
  //For Create only
  email?: string
};

export const VendorManager: ModelDefinition<VendorManagerFields> = {
  name: "vendor_manager",
  inflections: {
    plural: "vendor_managers",
    title: "VendorManager"
  },
  singleton: false,
  server_side_new: true
};

export type InventorySnapshotFields = {
  id: number,
  retailer_id: EntityFields['id'],
  vendor_id: EntityFields['id'],
  date: string,
  unit_count: number,
  product_count: number,
  retail_value: string,
  purchase_value: string,
  red_target_count: number,
  yellow_target_count: number,
  green_target_count: number,
  lock_version: number
}

export const InventorySnapshot: ModelDefinition<InventorySnapshotFields> = {
  name: "inventory_snapshot",
  inflections: {
    plural: "inventory_snapshots",
    title: "InventorySnapshot"
  },
  singleton: false,
  server_side_new: false
};