import React, {Fragment, FunctionComponent, Suspense} from 'react';

import { Nav, NavDropdown, Navbar, ProgressBar, Dropdown } from 'react-bootstrap';

import { useLoaders } from '../lib/DataLoader2';

import './Nav/styles.scss';
import RestfulModelStore, { FoundRecord } from '../lib/RestfulModelStore';
import { EntityFields, Entity, Session, User } from '../lib/Models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { UXTreeState } from 'analytics/lib/TreeStore';
import { RootUxState, RootPages } from 'analytics';

const VendorOptions = React.lazy(() => (import('./Nav/VendorOptions')));

type IncomingProps = {store: RestfulModelStore, ux_state: UXTreeState<RootUxState, RootPages>, entity: FoundRecord<EntityFields>};

const AppNav : FunctionComponent<IncomingProps> = (props) => {
  const {store, ux_state, entity} = props;

  const {user, page, entities } = useLoaders(() => {
    const session   = store.s(Session).fetch();
    const user      = session._found ? store.m(User).fetch(session.current_user_id) : undefined;
    const entities = user && user._found ? store.m(Entity).fetchMany(user.accessible_entity_ids) : store.emptyLoadingQuery<EntityFields>();
  
    return {
      session,
      user,
      entities,
      entity: entity,
      page: ux_state.getActiveSubtree() || 'dashboard'
    };
  }, [store, ux_state], [entity]); 


  const handleSelect = (key: any) => {
    ux_state.setActiveSubtree(key.toString());
  }

  const handleCompanySelect = (key: EntityFields['id']) => {
    ux_state.set('entity_id', key);
  }

  const handleAccountSelect = (key: string) => {
    switch(key) {
      case "logout": store.s(Session).destroy();
    }
  }

  let companies_dropdown, companies_menu;
  if(entities._loading)
    companies_menu = <Dropdown.Item title="Loading…" disabled></Dropdown.Item>;
  else
    companies_menu = entities.map((e, idx) => ((e._found === true) ? <Dropdown.Item key={e.id.toString()} eventKey={e.id.toString()}>{e.name}</Dropdown.Item> : "(Company Not Found)"));

  companies_dropdown = <NavDropdown onSelect={(key:any) => (handleCompanySelect(Number(key)))} id='company_selector' title={entity.name}>
    {companies_menu}
  </NavDropdown>;

  const user_dropdown = <NavDropdown onSelect={(key:any) => (handleAccountSelect(key))} id="user_menu" title={<span><FontAwesomeIcon icon={faUser}/></span>}> {
    (user && user._loaded) ? (
      <React.Fragment>
        <Dropdown.Item disabled >{user._found ? user.name : "Loading…"}</Dropdown.Item>
        <Dropdown.Divider/>
        <Dropdown.Item eventKey='logout'>Logout</Dropdown.Item>
      </React.Fragment>
    ) : (
      <Dropdown.Item disabled>Loading…</Dropdown.Item>
    )
  }</NavDropdown>;

  return (
    <Fragment>
      <Navbar expand="md" bg="light">
        <Navbar.Brand>
          KushMaster
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="main-nav"/>
        <Navbar.Collapse id="main-nav" className='justify-content-between'>
          {
            user?._found ? user.role == "retailer" ?
              <>
                <Nav activeKey={page} onSelect={(key) => (handleSelect(key))}>
                  <Nav.Link eventKey="dashboard">Dashboard</Nav.Link>
                  <Nav.Link eventKey="products">Products &amp; Pricing</Nav.Link>
                  <Nav.Link eventKey="vendors">Vendors &amp; Orders</Nav.Link>
                  <Nav.Link eventKey="inventory" disabled>Inventory</Nav.Link>
                </Nav>
              </>
              : //Vendor manager
              <Suspense fallback={""}>
                <VendorOptions page={page} user={user} vendor={entity} store={store} tree_store={ux_state}/>
              </Suspense> : "" //Empty string if user isn't loaded
          }
          <Nav className="justify-content-end">
          {companies_dropdown}
          {user_dropdown}
        </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div>{(() => {
        if(entity && entity._found && entity.job_processing && entity.job_progress) {
          let job_progress = entity.job_progress;
          return <ProgressBar 
            now={job_progress[1] - job_progress[0]} 
            max={job_progress[1]} 
            label={`${entity.job_processing} (${job_progress[1] - job_progress[0]}/${job_progress[1]})`}/>;
        } else
          return "";
      })()}</div>
    </Fragment>
  );
}

export default AppNav;