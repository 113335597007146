import RestfulModelStore, { Txn } from '../lib/RestfulModelStore';

import React, { PropsWithChildren, useState } from 'react';
import { Button, FormGroup, FormControl, Row, Container, Form } from 'react-bootstrap';
import { Session } from '../lib/Models';

import { useForm } from 'react-hook-form';

type IncomingProps = PropsWithChildren<{store: RestfulModelStore}>;
type ViewState = {
  email?: string,
  password?: string,
  create_txn?: Txn
};

const LoginForm = (props: IncomingProps) => {
  // function createSession(event: React.FormEvent<HTMLFormElement>) {
  //   event.preventDefault();
  //   console.log("The event is:", event);
  //   const state = this.state;
  //   const props = this.props;

  //   const new_obj = {
  //     greenbits_username: state.email,
  //     greenbits_password: state.password
  //   }
  //   this.setState({
  //     create_txn: props.store.s(Session).create(new_obj)
  //   });
  // }

  const [createTxn, setCreateTxn] = useState<Txn>();
  const {register, handleSubmit}  = useForm();

  function myHandleSubmit(data: any) {
    setCreateTxn(props.store.s(Session).create(data))
  }

  var create_status = createTxn && props.store.txn_status(createTxn);
  let message;
  if (create_status && create_status.status === 'error') {
    message = 'Invalid email address or password.';
  }
  return (
    <Form onSubmit={handleSubmit(myHandleSubmit)}>
      <Container>
        <Row>{message}</Row>
        <Row>
          <FormGroup controlId="email">
            <Form.Label>Email Address</Form.Label>
            <FormControl type="email" name="email" ref={register}/>
          </FormGroup>
        </Row>
        <Row>
          <FormGroup controlId="password">
            <Form.Label>Password</Form.Label>
            <FormControl type="password" name="password" ref={register}/>
          </FormGroup>
        </Row>
        <Row>
          <FormGroup controlId="login_type">
            {/* the non-unique IDs here are gross*/}
            <Form.Check defaultChecked inline ref={register} type="radio" label="Greenbits" name="login_type" id="1" value="retailer"/>
            <Form.Check inline ref={register} type="radio" label="Vendor Manager" name="login_type" id="2" value="vendor_manager"/>
          </FormGroup>
        </Row>
        <Row><Button type="submit" variant="primary">Login</Button></Row>
      </Container>
    </Form>
  );
}

export default LoginForm;