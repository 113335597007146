import LoaderWrapper, {DataLoader, ComponentProps, useLoaders} from './lib/DataLoader2';

import React, { Suspense, useState, useEffect } from 'react';

import AppNav from './components/Nav';

// import ProductPane from './components/ProductPane';
//import VendorsPane from './components/VendorsPane';
// import Dashboard   from './components/Dashboard';
import LoginForm   from './components/Login';

import { Col, Spinner } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.css';
import './analytics.scss';
import RestfulModelStore, { ModelRecord, Txn, ExistingRecord } from './lib/RestfulModelStore';
import {UserFields, Session, User, EntityFields, SessionFields, Company, Entity} from './lib/Models';
import TreeStore, { UXTreeState } from './lib/TreeStore';

const VendorsPane = React.lazy(() => (import('./components/VendorsPane')));
const ProductPane = React.lazy(() => (import('./components/ProductPane')));
const Dashboard = React.lazy(() => (import('./components/Dashboard')));

const VendorDashboard = React.lazy(() => (import('./components/VendorDashboard')));
const VendorRetailer  = React.lazy(() => (import('analytics/components/VendorRetailer')));

export type RootPages = "dashboard" | "vendors" | "products" | "vendor_dashboard" | "vendor_retailer";
export type RootUxState = {
  entity_id: EntityFields['id'],
  session_token: string
};

const Main : React.FunctionComponent<{store: RestfulModelStore, ux_state: any, tree_store: UXTreeState<RootUxState,RootPages>}> = function(props) {
  const store = props.store;
  const ux_state = props.ux_state;
  const tree_store = props.tree_store;

  const [sessionTxn, setSessionTxn] = useState<Txn|undefined>();
  const { user, session, entity_id, entity, page } = useLoaders(() => {
    const session_token = tree_store.get('session_token');
    /*
    Truth table for how session creation from tokens work
        
    Token | Txn | Txn Complete | Action           | Session Object
    --------------------------------------------------------------
        F  |  F  |     X       |                  | Fetch Session
        F  |  T  |     F       |                  | Empty Session (Should not be possible)
        F  |  T  |     T       |                  | Fetch Session
        T  |  F  |     X       | Session#create() | Empty Session
        T  |  T  |     F       |                  | Empty Session 
        T  |  T  |     T       | Delete Token     | Fetch Session

    */
    if(session_token) {
      if(!sessionTxn) {
        setSessionTxn(store.s(Session).create({token: session_token}));
      } else if(store.txn_status(sessionTxn)) {
        tree_store.set("session_token", undefined);
      }
    }
    const session = (!session_token || (sessionTxn && store.txn_status(sessionTxn))) ? store.s(Session).fetch() : store.emptyLoadingRecord<SessionFields>(0);

    
    const user    = (session._loaded && session._found && store.m(User).fetch(session.current_user_id)) || undefined;
    const active_entity_id = tree_store.get('entity_id');
    
    const entity_id = (user && user._found) && 
      (user.accessible_entity_ids.find((el) => (el === active_entity_id)) || user.accessible_entity_ids[0]) 
      || undefined;
    const entity = entity_id ? store.m(Entity).fetch(entity_id) : undefined;

    return {
      session,
      user,
      entity_id, 
      entity,
      page: tree_store.getActiveSubtree() || 'dashboard'
    }
  },[store,tree_store]);


  // For periodically refreshing the loading status of the Entities
  useEffect(() => {
    if(entity) {
      //The timer is used to refresh the entity object to render, e.g., the loading bar
      const timer = window.setInterval(() => (tick()), 60000);

      const tick = () => {
        store.m(Entity).fetch(entity.id, true);
      }
      return () => (
        window.clearInterval(timer)
      );  
    }
  });

  if(session && !session._loading && !session._found ) {
    return <div><LoginForm store={props.store}/></div>;
  } else if(!session || session._loading || !user || !user._found) {
    return <div>Loading…</div>;
  } else {
    if(!entity || !entity._found) {
      return <div>Loading…</div>;
    }
    
    let main_pane;
    if (user.role === 'retailer') {
      // const subscription = this.state.subscription;
  
      // if (subscription._loaded && !subscription._found) {
      //   main_pane = <SubscribePane store={store} company_id={this.state.company_id}/>;
      // } else 
      if (!main_pane) {
        switch(page) {
          case 'dashboard': main_pane = <Dashboard ux_state={ux_state} store={props.store} entity={entity}/>;break;
          case 'vendors': main_pane = <VendorsPane store={props.store} entity={entity as ExistingRecord<EntityFields>} tree_store={tree_store.subtree("vendors")} user={user}/>;break;
          case 'products': main_pane = <Col><ProductPane ux_state={ux_state} store={props.store} entity={entity}/></Col>;break;
          default: main_pane = <div>Whoops!</div>
        }
      }
    } else if(user.role === 'vendor_manager') {
      const activeSubtree = tree_store.getActiveSubtree();

      if(activeSubtree === "vendor_retailer") {
        main_pane = <VendorRetailer vendor={entity} store={store} tree_store={tree_store.subtree("vendor_retailer")} user={user}/>
      } else {
        main_pane = <VendorDashboard vendor={entity} store={store} tree_store={tree_store}></VendorDashboard>
      }
    } else {
      return <div>There's a problem with your account, please contact support@jointleaf.com</div> 
    }

    return <div>
      <AppNav ux_state={tree_store} store={props.store} entity={entity}/>
      <Suspense fallback={<Spinner animation="grow"/>}>
        {main_pane}
      </Suspense>
    </div>;
  }  
}

export default Main; 